import React, { useEffect, Fragment, useState } from "react";
import { Link, useParams } from "react-router-dom";
//Firebase
import { useFirebaseApp } from "reactfire";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faList,
  faFile,
  faFileInvoice,
  faPoll,
  faQuestion,
  faInfo,
  faHandPointUp,
  faWindowClose,
  faArrowAltCircleLeft,
} from "@fortawesome/free-solid-svg-icons";
import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip,
} from "recharts";
import { Tabs, Tab, ListGroup, Card, Modal } from "react-bootstrap";
import {
  Button,
  CardBody,
  CardHeader,
  Form,
  FormGroup,
  Input,
  Label,
  ListGroupItem,
  Table,
  CardFooter,
} from "reactstrap";
import {
  MDBContainer,
  MDBTabPane,
  MDBTabContent,
  MDBNav,
  MDBNavItem,
  MDBNavLink,
  MDBIcon,
  MDBTooltip,
} from "mdbreact";
import ReactStopwatch from "react-stopwatch";
import PerfectScrollbar from "react-perfect-scrollbar";

import background from "../assets/img/backgrounds/architect-showing-miniature-building-at-business-m-XPLV5K4.jpg";
import Swal from "sweetalert2";

import { Doughnut } from "react-chartjs-2";

import Pdf from "../assets/reglamento.pdf";

export const chartColors = [
  "#336699",
  "#99CCFF",
  "#999933",
  "#666699",
  "#CC9933",
  "#006666",
  "#3399FF",
  "#993300",
  "#CCCC99",
  "#666666",
  "#FFCC66",
  "#6699CC",
  "#663366",
  "#9999CC",
  "#CCCCCC",
  "#669999",
  "#CCCC66",
  "#CC6600",
  "#9999FF",
  "#0066CC",
  "#99CCCC",
  "#999999",
  "#FFCC00",
  "#009999",
  "#99CC33",
  "#FF9900",
  "#999966",
  "#66CCCC",
  "#339966",
  "#CCCC33",
  "#003f5c",
  "#665191",
  "#a05195",
  "#d45087",
  "#2f4b7c",
  "#f95d6a",
  "#ff7c43",
  "#ffa600",
  "#EF6F6C",
  "#465775",
  "#56E39F",
  "#59C9A5",
  "#5B6C5D",
  "#0A2342",
  "#2CA58D",
  "#84BC9C",
  "#CBA328",
  "#F46197",
  "#DBCFB0",
  "#545775",
];

let unsubscribeHands;

function Live() {
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    responsiveAnimationDuration: 500,
    cutoutPercentage: 60,
    legend: { display: false },
    tooltips: {
      callbacks: {
        label(tooltipItem, data) {
          const label = data.datasets[0].labels[tooltipItem.index] || "",
            value = data.datasets[0].data[tooltipItem.index];
          const output = ` ${label} : ${value} %`;
          return output;
        },
      },
      // Updated default tooltip UI
      shadowOffsetX: 1,
      shadowOffsetY: 1,
      shadowBlur: 8,
      backgroundColor: "#fff",
      titleFontColor: "#000",
      bodyFontColor: "#000",
    },
  };

  const firebaseApp = useFirebaseApp();
  const [tab, setTab] = useState("5");
  const [answer, setAnswer] = useState("");
  const [profile, setProfile] = useState(
    JSON.parse(sessionStorage.getItem("_profile"))
  );
  const [residentialUnit, setResidentialUnit] = useState(
    JSON.parse(sessionStorage.getItem("_residentialUnit"))
  );
  const [codeMeet, setCodeMeet] = useState(sessionStorage.getItem("codeMeet"));
  const [summary, setSummary] = useState([]);
  const [documents, setDocuments] = useState([]);
  const [records, setRecords] = useState([]);
  const [questions, setQuestions] = useState([]);
  const [qanswers, setQAnswers] = useState([]);
  const [nominal, setNominal] = useState(0);
  const [meetData, setMeetData] = useState({});

  const [questionActive, setQuestionActive] = useState({});
  const [show, setShow] = useState(false);
  const [handUp, setHandUp] = useState(
    localStorage.getItem("pth") !== null ? true : false
  );
  const [panel, setPanel] = useState(true);

  const [answerSelected, setAnswerSelected] = useState(0);

  const [typeUser, setTypeUser] = useState(sessionStorage.getItem("type"));
  const [unitInfo, setUnitInfo] = useState(
    JSON.parse(sessionStorage.getItem("_unitDoc"))
  );

  const [pathHand, setPathHand] = useState(localStorage.getItem("pth"));

  const fiveMinutes = 60000; // five minutes, or whatever makes sense for your app

  const enableTimerConnection = () => {
    let unitDoc = JSON.parse(sessionStorage._unitDoc);
    // "maintain connection"
    let interval;
    if (!interval) {
      setInterval(() => {
        firebaseApp
          .firestore()
          .collection("meets")
          .doc(sessionStorage.codeMeet)
          .collection("units")
          .doc(unitDoc.doc)
          .update({
            lastOnline: new Date().getTime(),
            live: true,
          })
          .then(() => {});
      }, fiveMinutes);
    } else {
      clearInterval(interval);
      interval = null;
    }
  };

  useEffect(() => {
    getSummary();
    if (typeUser == "owner") {
      enableTimerConnection();
    }
    getDocuments();
    getRecords();
    getMeet();
    getQuestions();
    //getQAinfo();
  }, [codeMeet]);

  const handleClickTab = (tabId) => {
    setTab(tabId);
  };

  const getMeet = () => {
    firebaseApp
      .firestore()
      .collection("meets")
      .doc(sessionStorage.codeMeet)
      .onSnapshot((snapshot) => {
        setMeetData(snapshot.data());
      });
  };

  const getSummary = () => {
    firebaseApp
      .firestore()
      .collection("summary")
      .doc(codeMeet)
      .collection("items")
      .orderBy("orden", "asc")
      .onSnapshot((snapshot) => {
        if (snapshot.size !== 0) {
          const data = snapshot.docs.map((d) => ({ id: d.id, ...d.data() }));
          setSummary(data);
        }
      });
  };

  const getDocuments = () => {
    firebaseApp
      .firestore()
      .collection("downloads")
      .doc(codeMeet)
      .collection("items")
      .onSnapshot((snapshot) => {
        if (snapshot.size !== 0) {
          const data = snapshot.docs.map((d) => ({ id: d.id, ...d.data() }));
          setDocuments(data);
        }
      });
  };

  const getRecords = () => {
    firebaseApp
      .firestore()
      .collection("record")
      .doc(codeMeet)
      .collection("items")
      .orderBy("orden", "asc")
      .onSnapshot((snapshot) => {
        if (snapshot.size !== 0) {
          const data = snapshot.docs.map((d) => ({ id: d.id, ...d.data() }));
          setRecords(data);
        }
      });
  };

  const getQuestions = () => {
    const unitInfo = JSON.parse(sessionStorage.getItem("_unitDoc"));
    firebaseApp
      .firestore()
      .collection("votes")
      .doc(codeMeet)
      .collection("questions")
      .onSnapshot((snapshot) => {
        console.log(snapshot);
        if (snapshot.size !== 0) {
          const data = snapshot.docs.map((d) => ({
            id: d.id,
            path: d.ref,
            ...d.data(),
          }));
          setQuestions(data);
          data.forEach((question, index) => {
            if (!question.isCompleted) {
              console.log(question.path.path);
              firebaseApp
                .firestore()
                .doc(question.path.path)
                .collection("answers")
                .where("idUnit", "==", unitInfo.id)
                .onSnapshot((answers) => {
                  console.log(answers.size);
                  if (answers.size !== 0) {
                    const dataans = answers.docs.map((d) => ({
                      id: d.id,
                      path: d.ref,
                      ...d.data(),
                    }));
                    console.log(dataans);
                    handleClose();
                    data[index].opcionSelected = dataans[0].opcionSelected;
                  } else {
                    data[index].opcionSelected = "";
                    setQuestionActive(question);
                    handleShow();
                  }
                });
            } else {
              setNominal(question.nominal);
              handleClose();
            }
            firebaseApp
              .firestore()
              .doc(question.path.path)
              .collection("answers")
              .onSnapshot((answers) => {
                if (answers.size !== 0) {
                  const qa = answers.docs.map((el) => ({
                    id: el.id,
                    ...el.data(),
                  }));
                  data[index].answers = qa;
                } else {
                  data[index].answers = [];
                }
                setQAnswers([...data]);
              });
          });
        } else {
          handleClose();
        }
      });
  };

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const saveVote = () => {
    //console.log("resp ==> ", answer);
    if (answer.length !== 0) {
      firebaseApp
        .firestore()
        .collection("votes")
        .doc(codeMeet)
        .collection("questions")
        .doc(questionActive.id)
        .collection("answers")
        .add({
          coeficient: unitInfo.votePercentage,
          opcionSelected: answer,
          unit: unitInfo.name,
          idUnit: unitInfo.id,
        })
        .then(() => {
          handleClose();
        });
    } else {
      Swal.fire({
        icon: "warning",
        title: "Oops!",
        text: "Por favor selecciona una opción para votar.",
      });
    }
  };

  const calculateVotesNumber = (answers, option) => {
    if (answers) {
      let info = answers.filter((el) => el.opcionSelected === option);
      if (info.length !== 0) {
        let total = 0;
        info.forEach((dato) => {
          total = total + dato.coeficient;
        });
        return total;
      } else {
        return 0;
      }
    }
    return 0;
  };

  const calculateVotes = (question, option, qi, di) => {
    if (question.answers) {
      let info = question.answers.filter((el) => el.opcionSelected === option);
      if (info.length !== 0) {
        let total = 0;
        info.forEach((dato) => {
          total = total + dato.coeficient;
        });
        return `${total}%`;
      } else {
        return "0%";
      }
    }
    return `0%`;
  };

  const sumVotes = (question, option) => {
    if (question.answers) {
      let info = question.answers.filter((el) => el.opcionSelected === option);
      if (info.length !== 0) {
        let total = 0;
        info.forEach((dato) => {
          total++;
        });
        return `${total}`;
      } else {
        return "0";
      }
    }
    return `0`;
  };

  const getLabelsQuestion = (question) => {
    let tempArray = [];
    let tempArrayData = [];
    question.description.forEach((option, i) => {
      tempArray.push(option);
      tempArrayData.push(calculateVotesNumber(question.answers, option));
    });
    return { labels: tempArray, data: tempArrayData };
  };

  const getValuesQuestion = (questionDesc) => {
    let tempArray = [];
    questionDesc.forEach((option, i) => {
      tempArray.push(option);
    });
    return tempArray;
  };

  const renderQuestion = () => {
    return (
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        animation={false}
      >
        <Modal.Header>
          <Modal.Title>
            {questionActive.name ? questionActive.name : ""}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            {questionActive.description &&
              questionActive.description.map((text, index) => (
                <FormGroup key={index.toString()}>
                  <Button
                    color={text == answer ? "green" : "gray"}
                    onClick={(e) => {
                      setAnswer(text);
                      setAnswerSelected(index);
                    }}
                    block
                  >
                    {text}
                  </Button>
                </FormGroup>
              ))}
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <p style={{ width: "100%", margin: "0 0 20px 0" }}>
            Respuesta Seleccionada: {answer}
          </p>

          <Button block color="primary" onClick={saveVote}>
            Votar
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const requestHand = () => {
    const unitInfo = JSON.parse(sessionStorage.getItem("_unitDoc"));
    if (pathHand === null) {
      firebaseApp
        .firestore()
        .collection("hands")
        .doc(codeMeet)
        .collection("requests")
        .add({
          dueDate: new Date(),
          isWaiting: true,
          isActive: false,
          idUnit: unitInfo.id,
          nameUnit: unitInfo.name,
          nameOwner: `${profile.firstName} ${profile.lastName}`,
        })
        .then((resp) => {
          setPathHand(resp.path);
          localStorage.setItem("pth", resp.path);
          setHandUp(true);
          snapshotHand(resp.path);
        });
    } else {
      firebaseApp
        .firestore()
        .doc(pathHand)
        .update({
          isWaiting: false,
        })
        .then(() => {
          setPathHand(null);
          localStorage.removeItem("pth");
          setHandUp(false);
        });
    }
  };

  const snapshotHand = (path) => {
    unsubscribeHands = firebaseApp
      .firestore()
      .doc(path)
      .onSnapshot((snapshot) => {
        let data = snapshot.data();
        if (data.isWaiting === false && data.isActive === false) {
          setPathHand(null);
          localStorage.removeItem("pth");
          setHandUp(false);
          unsubscribeHands();
        } else if (data.isActive === true) {
          Swal.fire({
            icon: "success",
            text: "El administrador te ha dado la palabra.",
          });
        }
      });
  };

  return (
    <Fragment>
      <div
        className="bg-img "
        style={{
          backgroundColor: "#111",
          height: "100vh",
        }}
      >
        {/*====================  header ====================*/}
        <header className="header-live">
          <div className="container">
            <div className="row">
              <div className="col-md-1 col-sm-12 contentTop">
                <img
                  className="logo_foot_top"
                  src="https://panel.admon.com.co/recursos//front/landing/img/white-logoAdmon.png"
                />
              </div>

              <div className="col-md-4 col-sm-12">
                {/* logo */} <strong>{residentialUnit.name}</strong>
                <br />
                <strong>Nombres</strong>: {profile.firstName} {profile.lastName}
                <br />
                <strong>Propiedad</strong>: {profile.unit}
              </div>

              <div className="col-md-4 col-sm-12">
                <strong>Valor Coeficiente</strong> : {unitInfo.votePercentage} %
                <br />
                <strong>Quorum Actual</strong>:{" "}
                {meetData.quoromLast ? meetData.quoromLast.toFixed(2) : 0} %
                <br />
              </div>
              <div className="col-md-3 col-sm-12">
                <a
                  href={`https://zoom.us/j/${meetData.idmeet}`}
                  target="_blank"
                >
                  <button className="btn btn-primary">Abrir Enlace Zoom</button>
                </a>
              </div>
            </div>
          </div>
        </header>
        {meetData.timer && (
          <aside className="counter">
            {" "}
            <ReactStopwatch
              seconds={0}
              minutes={0}
              hours={0}
              onChange={({ hours, minutes, seconds }) => {
                // do something
              }}
              onCallback={() => console.log("Finish")}
              render={({ formatted, hours, minutes, seconds }) => {
                return (
                  <div>
                    <p>Tiempo de Intervención: {formatted}</p>
                  </div>
                );
              }}
            />
          </aside>
        )}
        {/*====================  End of header  ====================*/}
        {/*====================  content ====================*/}
        <div className="container" style={{ maxWidth: "100%", width: "100%" }}>
          {/* {typeUser !== "guest" && renderQuestion()} */}
          <div className="row">
            <div className="col-12 containerzoom" style={{ padding: 0 }}>
              {/* <iframe
                src={
                  "https://zoomlive.admon.com.co/?user=" +
                  profile.unit +
                  "&correo=" +
                  profile.email +
                  "&code=" +
                  sessionStorage._sessionCode
                }
                style={{
                  width: panel ? "calc(100% - 450px)" : "calc(100% - 50px)",
                }}
                className="iframe_zoom"
                allow="camera *;microphone *"
              ></iframe> */}
              <div className="panel" style={{ width: "100%" }}>
                <div className="panelContent">
                  <div className="classic-tabs">
                    <MDBNav classicTabs color="orange">
                      {/* <MDBNavItem>
                        <MDBTooltip placement="left">
                          <MDBNavLink
                            link
                            to="#"
                            onClick={() => setPanel(!panel)}
                          >
                            <FontAwesomeIcon
                              style={panel ? { color: "red" } : {}}
                              icon={
                                panel ? faWindowClose : faArrowAltCircleLeft
                              }
                            />
                          </MDBNavLink>
                          <div>{panel ? "Cerrar" : "Abrir"}</div>
                        </MDBTooltip>
                      </MDBNavItem> */}
                      <MDBNavItem>
                        <MDBTooltip placement="left">
                          <MDBNavLink
                            link
                            to="#"
                            active={tab === "5"}
                            onClick={() => handleClickTab("5")}
                          >
                            <FontAwesomeIcon
                              style={tab === "5" ? { color: "#4285f4 " } : {}}
                              icon={faPoll}
                            />
                          </MDBNavLink>
                          <div>Votaciones</div>
                        </MDBTooltip>
                      </MDBNavItem>

                      {/* <MDBNavItem>
                        <MDBTooltip placement="left">
                          <MDBNavLink
                            link
                            to="#"
                            active={tab === "1"}
                            onClick={() => handleClickTab("1")}
                          >
                            <FontAwesomeIcon
                              style={tab === "1" ? { color: "#4285f4 " } : {}}
                              icon={faInfo}
                            />
                          </MDBNavLink>
                          <div>Información Asamblea</div>
                        </MDBTooltip>
                      </MDBNavItem> */}

                      <MDBNavItem>
                        <MDBTooltip placement="left">
                          <MDBNavLink
                            link
                            to="#"
                            active={tab === "2"}
                            onClick={() => handleClickTab("2")}
                          >
                            <FontAwesomeIcon
                              style={tab === "2" ? { color: "#4285f4 " } : {}}
                              icon={faList}
                            />
                          </MDBNavLink>
                          <div>Orden del Día</div>
                        </MDBTooltip>
                      </MDBNavItem>
                      <MDBNavItem>
                        <MDBTooltip placement="left">
                          <MDBNavLink
                            link
                            to="#"
                            active={tab === "3"}
                            onClick={() => handleClickTab("3")}
                          >
                            <FontAwesomeIcon
                              style={tab === "3" ? { color: "#4285f4 " } : {}}
                              icon={faFile}
                            />
                          </MDBNavLink>
                          <div>Documentos</div>
                        </MDBTooltip>
                      </MDBNavItem>
                      <MDBNavItem>
                        <MDBTooltip placement="left">
                          <MDBNavLink
                            link
                            to="#"
                            active={tab === "4"}
                            onClick={() => handleClickTab("4")}
                          >
                            <FontAwesomeIcon
                              style={tab === "4" ? { color: "#4285f4 " } : {}}
                              icon={faFileInvoice}
                            />
                          </MDBNavLink>
                          <div>Acta</div>
                        </MDBTooltip>
                      </MDBNavItem>

                      <MDBNavItem>
                        <MDBTooltip placement="left">
                          <MDBNavLink
                            link
                            to="#"
                            active={tab === "6"}
                            onClick={() => handleClickTab("6")}
                          >
                            <FontAwesomeIcon
                              style={tab === "6" ? { color: "#4285f4 " } : {}}
                              icon={faQuestion}
                            />
                          </MDBNavLink>
                          <div>Ayuda</div>
                        </MDBTooltip>
                      </MDBNavItem>
                    </MDBNav>
                    <MDBTabContent
                      activeItem={tab}
                      style={{ display: panel ? "block" : "none" }}
                    >
                      <MDBTabPane tabId="1">
                        <h3 className="titletab">Información</h3>
                        <Card>
                          <CardBody>
                            <strong>Presidente</strong>: {meetData.president}
                            <br />
                            <strong>Secretario</strong>: {meetData.secretary}
                            <br />
                          </CardBody>
                        </Card>
                        <br />
                        <Card>
                          <CardBody>
                            <strong>Unidad</strong>: {residentialUnit.name}
                            <br />
                            <strong>Nombres</strong>: {profile.firstName}{" "}
                            {profile.lastName}
                            <br />
                            <strong>Propiedad</strong>: {profile.unit}
                          </CardBody>
                        </Card>
                        <br />

                        <Card>
                          <CardBody>
                            <strong>Valor Coeficiente</strong> :{" "}
                            {unitInfo.votePercentage} %
                            <br />
                            <strong>Quorum Actual</strong>:{" "}
                            {meetData.quoromLast
                              ? meetData.quoromLast.toFixed(2)
                              : 0}{" "}
                            %
                            <br />
                          </CardBody>
                        </Card>
                      </MDBTabPane>
                      <MDBTabPane tabId="2">
                        <PerfectScrollbar>
                          <h3 className="titletab">Orden del Día</h3>
                          <div className="scrollContainer">
                            <PerfectScrollbar>
                              <ListGroup>
                                {summary.map((item, index) => (
                                  <ListGroup.Item
                                    variant={item.isCompleted ? "success" : ""}
                                    key={index.toString()}
                                  >
                                    {item.name}
                                  </ListGroup.Item>
                                ))}
                              </ListGroup>
                            </PerfectScrollbar>
                          </div>
                        </PerfectScrollbar>
                      </MDBTabPane>
                      <MDBTabPane tabId="3">
                        <h3 className="titletab">Documentos</h3>
                        <div className="scrollContainer">
                          <PerfectScrollbar>
                            {documents.map((item, index) => (
                              <ListGroup.Item key={index.toString()}>
                                {item.name} -{" "}
                                <a target="_blank" href={item.link}>
                                  Descargar
                                </a>
                              </ListGroup.Item>
                            ))}
                          </PerfectScrollbar>
                        </div>
                      </MDBTabPane>
                      <MDBTabPane tabId="4">
                        <h3 className="titletab">Acta</h3>
                        <div className="scrollContainer">
                          <PerfectScrollbar>
                            <ListGroup>
                              {records.map((item, index) => (
                                <ListGroup.Item key={index.toString()}>
                                  {item.name}
                                </ListGroup.Item>
                              ))}
                            </ListGroup>
                          </PerfectScrollbar>
                        </div>
                      </MDBTabPane>
                      <MDBTabPane tabId="5">
                        <h3 className="titletab">Votaciones</h3>
                        <br />
                        <div className="scrollContainer">
                          <PerfectScrollbar>
                            <div className="votacionCurrent">
                              <h5>Votacion En Curso</h5>
                              <div className="row">
                                <div className="col-12">
                                  {show && (
                                    <Card>
                                      <CardHeader>
                                        {questionActive.name
                                          ? questionActive.name
                                          : ""}
                                      </CardHeader>
                                      <CardBody>
                                        <Form>
                                          {questionActive.description &&
                                            questionActive.description.map(
                                              (text, index) => (
                                                <FormGroup
                                                  key={index.toString()}
                                                >
                                                  <Button
                                                    color={
                                                      text == answer
                                                        ? "green"
                                                        : "gray"
                                                    }
                                                    onClick={(e) => {
                                                      setAnswer(text);
                                                      setAnswerSelected(index);
                                                    }}
                                                    block
                                                  >
                                                    {text}
                                                  </Button>
                                                </FormGroup>
                                              )
                                            )}
                                        </Form>
                                      </CardBody>
                                      <CardFooter>
                                        <p
                                          style={{
                                            width: "100%",
                                            margin: "0 0 20px 0",
                                          }}
                                        >
                                          Respuesta Seleccionada: {answer}
                                        </p>

                                        <Button
                                          block
                                          color="primary"
                                          onClick={saveVote}
                                        >
                                          Votar
                                        </Button>
                                      </CardFooter>
                                    </Card>
                                  )}
                                </div>
                              </div>
                              {!show && (
                                <p>
                                  No hay votaciones en curso, en esta sección
                                  saldra la pregunta en el momento que el
                                  administración la habilite.
                                </p>
                              )}
                              <br />
                            </div>
                            <div className="votacionesHistory">
                              <h5>Historial Votaciones</h5>
                              <div className="row">
                                {questions.length == 0 && (
                                  <div className="col-12">
                                    <p>
                                      No hay en el momento historial de
                                      votaciones, en esta sección saldra la
                                      pregunta en el momento que el
                                      administración las cierre.
                                    </p>
                                  </div>
                                )}
                                {questions.length !== 0 &&
                                  questions.map((question, index) => (
                                    <>
                                      {question.isCompleted && (
                                        <div
                                          className="col-md-6 col-sm-12"
                                          key={index.toString()}
                                        >
                                          <div key={index.toString()}>
                                            <Card>
                                              <CardHeader>
                                                <h5>{question.name}</h5>
                                              </CardHeader>
                                              <CardBody>
                                                <div
                                                  style={{
                                                    height: "150px",
                                                    marginBottom: 10,
                                                  }}
                                                >
                                                  {question.answers && (
                                                    <>
                                                      {question.answers.length >
                                                        0 && (
                                                        <Doughnut
                                                          data={{
                                                            datasets: [
                                                              {
                                                                labels:
                                                                  getLabelsQuestion(
                                                                    question
                                                                  ).labels,
                                                                data: getLabelsQuestion(
                                                                  question
                                                                ).data,
                                                                backgroundColor:
                                                                  chartColors,
                                                              },
                                                            ],
                                                          }}
                                                          options={options}
                                                          height={150}
                                                          width={100}
                                                        />
                                                      )}
                                                    </>
                                                  )}
                                                </div>
                                                <Table>
                                                  <thead>
                                                    <tr>
                                                      <th>#</th>
                                                      <th>Opciones</th>
                                                      <th>%</th>
                                                      <th>Votos</th>
                                                    </tr>
                                                  </thead>
                                                  <tbody>
                                                    {question.description.map(
                                                      (option, i) => (
                                                        <tr key={i.toString()}>
                                                          <th scope="row">
                                                            {i + 1}
                                                          </th>
                                                          <td>{option}</td>
                                                          <td>
                                                            {calculateVotes(
                                                              question,
                                                              option,
                                                              index,
                                                              i
                                                            )}
                                                          </td>
                                                          <td>
                                                            {" "}
                                                            {sumVotes(
                                                              question,
                                                              option
                                                            )}
                                                          </td>
                                                        </tr>
                                                      )
                                                    )}
                                                    <tr>
                                                      <th scope="row">
                                                        {question.description
                                                          .length + 1}
                                                      </th>
                                                      <td>No votaron</td>
                                                      <td></td>
                                                      {question.answers && (
                                                        <td>
                                                          {question.nominal -
                                                            question.answers
                                                              .length}
                                                        </td>
                                                      )}
                                                    </tr>
                                                  </tbody>
                                                </Table>
                                              </CardBody>
                                              {/* {question.opcionSelected != "" && (
                                      <CardFooter className="text-muted">
                                        Tu voto fue por:
                                        {question.opcionSelected}
                                      </CardFooter>
                                    )}
                                    {question.opcionSelected == "" && (
                                      <CardFooter className="text-muted">
                                        No participaste en esta votación
                                      </CardFooter>
                                    )} */}
                                            </Card>
                                            <br />
                                          </div>
                                        </div>
                                      )}
                                    </>
                                  ))}
                              </div>
                            </div>
                          </PerfectScrollbar>
                        </div>
                      </MDBTabPane>
                      <MDBTabPane tabId="6">
                        <h3 className="titletab">Ayuda</h3>
                        <div className="scrollContainer">
                          <Card>
                            <CardHeader>Soporte Whatsapp</CardHeader>

                            <CardBody>
                              <a
                                target="_blank"
                                href="https://api.whatsapp.com/send?phone=573162712141"
                              >
                                Si necesitas ayudas puedes comunicarte con
                                nuestro soporte Whatsapp para ayudarte en
                                problemas tecnicos
                              </a>
                            </CardBody>
                          </Card>
                          <br />

                          <Card>
                            <CardHeader>Reglamento de la Asamblea</CardHeader>
                            <CardBody>
                              <a href={Pdf} target="_blank">
                                Descarga el Reglamento de la Asamblea
                              </a>
                            </CardBody>
                          </Card>
                          <br />

                          <Card>
                            <CardHeader>Video Instructivo</CardHeader>
                            <CardBody>
                              <iframe
                                width="100%"
                                height="250px"
                                src="https://www.youtube.com/embed/dX7v78rjBuQ"
                                style={{ border: 0 }}
                              ></iframe>
                            </CardBody>
                          </Card>
                        </div>
                      </MDBTabPane>
                    </MDBTabContent>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* {typeUser !== "guest" && (
        <aside className="hand">
          <div
            className="handButton"
            onClick={() => requestHand()}
            style={!handUp ? { background: "#10d3ff" } : { background: "red" }}
          >
            <FontAwesomeIcon icon={faHandPointUp} />
          </div>
          <div className="texthand">
            {!handUp ? "LEVANTAR LA MANO" : "CANCELAR SOLICITUD"}
          </div>
        </aside>
      )} */}
    </Fragment>
  );
}

export default Live;
