import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

//Firebase
import "firebase/firestore";
import firebaseConfig from "../services/firebaseConfig";
import { FirebaseAppProvider } from "reactfire";

import ComingSoon08 from "../pages/landing";

import NotFound from "../pages/NotFound";
import Live from "../pages/live";

function App() {
  return (
    <FirebaseAppProvider firebaseConfig={firebaseConfig}>
      <Router>
        <Switch>
          <Route
            exact
            path={process.env.PUBLIC_URL + "/live"}
            component={Live}
          />
          <Route
            exact
            path={process.env.PUBLIC_URL + "/:asamblea/:tokenUser"}
            component={ComingSoon08}
          />
          <Route
            exact
            path={process.env.PUBLIC_URL + "/:asamblea/"}
            component={ComingSoon08}
          />

          <Route exact component={NotFound} />
        </Switch>
      </Router>
    </FirebaseAppProvider>
  );
}

export default App;
