const firebaseConfig = {
  apiKey: "AIzaSyBuD_xJpwMNj_3JBZ2MmwXSatOIbIo4Fag",
  authDomain: "admon-asambleas.firebaseapp.com",
  projectId: "admon-asambleas",
  storageBucket: "admon-asambleas.appspot.com",
  messagingSenderId: "916628754373",
  appId: "1:916628754373:web:1ba8bdbd33818d31eac5ac",
  measurementId: "G-XJQ5EC0N82"
}

export default firebaseConfig;
