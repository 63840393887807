import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import { ReactSVG } from "react-svg";

const NotFound = () => {
  return (
    <Fragment>
      
      <div
        className="cs-07-page-wrapper h-100 bg-img d-flex flex-column justify-content-between"
        style={{
          backgroundImage: `url(${process.env.PUBLIC_URL +
            "/assets/img/backgrounds/coming-soon-07.jpg"})`
        }}
      >
        {/*====================  header ====================*/}
        <header className="cs-07-header space-pt--30 space-pb--30">
          <div className="container">
            <div className="row">
              <div className="col-6">
                {/* logo */}
                <div className="cs-07-logo space-mb--35">
                  <Link to={process.env.PUBLIC_URL + "/"}>
                    <img
                      src={
                        process.env.PUBLIC_URL + "/assets/img/logo/logo-07.png"
                      }
                      className="img-fluid"
                      alt=""
                    />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </header>
        {/*====================  End of header  ====================*/}
        {/*====================  content ====================*/}
        <div className="cs-07-content">
          <div className="container">
            <div className="row">
              <div className="col-lg-10 ml-auto mr-auto">
                <h2 className="cs-07-content__title text-center">
                  Opps..<br/> Ha ocurrido un error
                </h2>

                <div className="cs-07-subscription-wrapper space-mt--50 text-center">
                  <div className="mc-newsletter-form">
                    
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*====================  End of content  ====================*/}
        {/*====================  footer ====================*/}
        <footer className="cs-07-footer space-pt--25 space-pb--25">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-12 col-md-6">
                {/* copyright */}
                
              </div>
              <div className="col-12 col-md-6">
                {/* social icons */}
                
              </div>
            </div>
          </div>
        </footer>
        {/*====================  End of footer  ====================*/}
      </div>
    </Fragment>
  );
};

export default NotFound;
