import urls from "./apiConfig";
import axios from "axios";

export const getLoginAdmon = async (obj) => {
  try {
    const responseToken = await axios.get(`${urls.urlToken}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization:
          "Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJkYXRlIjoiMjAyMS0wMi0xNCAyMjoxOTozMy4wNTAzNjUiLCJ0aW1lem9uZV90eXBlIjozLCJ0aW1lem9uZSI6IkFtZXJpY2FcL05ld19Zb3JrIn0.PJ3xUojI47rgzv1DW53vrrKBhbYb9UI1GG4SXP8A664",
      },
    });
    const responseLoginUser = await axios.post(
      `${urls.urlBaseAdmon}ResidentLogin/`,
      obj,
      {
        headers: {
          Authorization: `Bearer ${responseToken.data.data}`,
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return responseLoginUser;
  } catch (error) {
    console.log("Handle error", error);
  }
};

export const getCodebyMeet = async (obj) => {
  try {
    const responseToken = await axios.get(`${urls.urlToken}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization:
          "Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJkYXRlIjoiMjAyMS0wMi0xNCAyMjoxOTozMy4wNTAzNjUiLCJ0aW1lem9uZV90eXBlIjozLCJ0aW1lem9uZSI6IkFtZXJpY2FcL05ld19Zb3JrIn0.PJ3xUojI47rgzv1DW53vrrKBhbYb9UI1GG4SXP8A664",
      },
    });
    const responsegetCodebyMeet = await axios.get(
      `${urls.urlBaseAdmon2}validarcodigo?codigo=${obj}`,
      {
        headers: {
          Authorization: `Bearer ${responseToken.data.data}`,
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return responsegetCodebyMeet;
  } catch (error) {
    console.log("Handle error", error);
  }
};

export const getCodeInfo = async (code) => {
  try {
    const responseTokenInfo = await axios.get(
      `${urls.urlBase}asamblea/?search=${code}`
    );
    return responseTokenInfo;
  } catch (error) {
    console.log("Handle error", error);
  }
};
