import React, { useState, useEffect, Fragment, useRef } from "react";
import { Link, useParams } from "react-router-dom";
import { ReactSVG } from "react-svg";
import Countdown from "react-countdown";
import Renderer from "../components/countdown/Renderer";
import Swal from "sweetalert2";
import { useFirebaseApp } from "reactfire";
import {
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  Alert,
  Table,
} from "reactstrap";
import { getLoginAdmon, getCodeInfo, getCodebyMeet } from "../services/api";
import background from "../assets/img/backgrounds/coming-soon-08.jpg";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";

const ComingSoon08 = () => {
  const fiveMinutes = 60000;

  const inputFile = useRef(null);
  const history = useHistory();

  // Firebase
  const firebaseApp = useFirebaseApp();

  // Params
  let { asamblea, tokenUser } = useParams();

  // States
  const [residentialUnit, setResidentialUnit] = useState({});
  const [profile, setProfile] = useState({});
  const [userLink, setUserlink] = useState(false);
  const [testConnection, setTestConnection] = useState(false);
  const [testPermission, setTestPermission] = useState(false);
  const [type, setType] = useState(1);
  const [showTestPermission, setShowTestPermission] = useState(false);
  const [showButtonPermission, setShowButtonPermission] = useState(true);
  const [dateMeet, setDateMeet] = useState("");
  const [meet, setMeet] = useState({
    active: false,
    waitingRoom: false,
  });
  const [units, setUnits] = useState([]);
  const [unitsLive, setUnitsLive] = useState([]);
  const [inMeetUnits, setInMeetUnits] = useState(0);
  const [apartamentoUsuario, setApartamentoUsuario] = useState("");

  // Forms
  const { register, errors, handleSubmit } = useForm();
  const {
    register: register2,
    errors: errors2,
    handleSubmit: handleSubmit2,
  } = useForm();

  const {
    register: register3,
    errors: errors3,
    handleSubmit: handleSubmit3,
  } = useForm();

  const onTestClick = () => {
    setShowButtonPermission(false);
    setShowTestPermission(true);
    checkPermission();
    //    setTestPermission(true);
  };

  const enableTimerConnection = () => {
    let unitDoc = JSON.parse(sessionStorage._unitDoc);
    // "maintain connection"
    //   console.log(unitDoc);
    let interval;
    if (!interval) {
      setInterval(() => {
        firebaseApp
          .firestore()
          .collection("meets")
          .doc(sessionStorage.codeMeet)
          .collection("units")
          .doc(unitDoc.doc)
          .update({
            lastOnline: new Date().getTime(),
            live: true,
          })
          .then(() => {
            console.log("Update Online");
          });
      }, fiveMinutes);
    } else {
      clearInterval(interval);
      interval = null;
    }
  };

  const errorMessage = (message, e) => {
    Swal.fire({
      icon: "error",
      text: typeof e == "undefined" ? "" : e,
      title: message,
    });
    console.error(message, typeof e == "undefined" ? "" : e);
    //alert(message);
    setShowButtonPermission(true);
    setShowTestPermission(false);
  };

  const onSubmit = (data) => {
    if (apartamentoUsuario !== "") {
      Swal.fire({
        icon: "info",
        title: "Ingresando...",
        text: "Estamos validando tu información, espera un momento.",
        didOpen: () => {
          Swal.showLoading();
        },
      });

      let unitInfo = units.find((el) => el.doc === apartamentoUsuario);

      sessionStorage.setItem(
        "_profile",
        JSON.stringify({
          unit: unitInfo.name,
          email: `${unitInfo.doc}@propietario.com`,
          firstName: data.nombreUser,
          lastName: data.apellidosUser,
        })
      );

      sessionStorage.setItem("type", "owner");

      sessionStorage.setItem(
        "_unitDoc",
        JSON.stringify({
          doc: unitInfo.doc,
          id: unitInfo.id,
          name: unitInfo.name,
          votePercentage: parseFloat(unitInfo.votePercentage),
        })
      );

      sessionStorage.setItem(
        "_residentialUnit",
        JSON.stringify({
          name: residentialUnit.name,
        })
      );

      firebaseApp
        .firestore()
        .collection("meets")
        .doc(asamblea)
        .collection("units")
        .doc(unitInfo.doc)
        .update({
          live: true,
          lastOnline: new Date().getTime(),
        })
        .then(() => {
          Swal.close();
          //setUserlink(true);
          // setTestConnection(true);
          passToWaitingRoom();
          setProfile({
            unit: unitInfo.name,
          });

          enableTimerConnection();
        });
    } else {
      Swal.fire({
        icon: "warning",
        title: "Oops!",
        text: "Por favor indica tu inmueble.",
      });
    }
  };

  const onSubmitInvitado = (data) => {
    sessionStorage.setItem(
      "_profile",
      JSON.stringify({
        unit: "Invitado - " + data.nameInvitado + " - " + data.cargoInvitado,
        email: "invitado@invitado.com",
        firstName: data.nameInvitado,
        lastName: data.cargoInvitado,
      })
    );
    sessionStorage.setItem(
      "_residentialUnit",
      JSON.stringify({
        name: residentialUnit.name,
      })
    );

    sessionStorage.setItem(
      "_unitDoc",
      JSON.stringify({
        doc: "",
        id: "",
        name: "Invitado",
        votePercentage: 0,
      })
    );

    sessionStorage.setItem("type", "guest");

    setTestConnection(true);
  };

  const onSubmitResidente = (data) => {
    sessionStorage.setItem(
      "_profile",
      JSON.stringify({
        unit:
          "Residente - " + data.nombreResidente + " " + data.apellidosResidente,
        email: "residente@residente.com",
        firstName: data.nombreResidente,
        lastName: data.apellidosResidente,
      })
    );
    sessionStorage.setItem(
      "_residentialUnit",
      JSON.stringify({
        name: residentialUnit.name,
      })
    );

    sessionStorage.setItem(
      "_unitDoc",
      JSON.stringify({
        doc: "",
        id: "",
        name: "Residente",
        votePercentage: 0,
      })
    );

    sessionStorage.setItem("type", "guest");

    setTestConnection(true);
  };

  const passToWaitingRoom = (data) => {
    setUserlink(true);
    setTestConnection(false);
  };

  const checkPermission = () => {
    if (window.location.protocol === "https:") {
      navigator.getUserMedia =
        navigator.getUserMedia ||
        navigator.webkitGetUserMedia ||
        navigator.mozGetUserMedia ||
        navigator.msGetUserMedia;
      if (navigator.getUserMedia) {
        navigator.getUserMedia(
          { audio: true, video: true },
          function (stream) {
            setTestPermission(true);
            setShowTestPermission(false);

            document.querySelector("video").srcObject = stream;
            document.querySelector("video").play();
            var mediaStreamTrack = stream.getVideoTracks()[0];

            if (typeof mediaStreamTrack != "undefined") {
              mediaStreamTrack.onended = function () {
                //for Chrome.
                errorMessage("La camara esta ocupada!");
              };
            } else errorMessage("Permiso Denegado!");
          },
          function (e) {
            var message;
            switch (e.name) {
              case "NotFoundError":
              case "DevicesNotFoundError":
                message = "Configura tu camara web primero.";
                break;
              case "SourceUnavailableError":
                message = "La camara esta ocupada";
                break;
              case "PermissionDeniedError":
              case "SecurityError":
                message = "Permiso Denegado!";
                break;
              default:
                errorMessage("Rechazado!", e);
                return;
            }
            errorMessage(message);
          }
        );
      } else errorMessage("Navegador no compatible!");
    } else errorMessage("Usa Https para abrir esta pagina");
  };

  const getMeet = () => {
    firebaseApp
      .firestore()
      .collection("meets")
      .doc(asamblea)
      .onSnapshot((snapshot) => {
        setMeet(snapshot.data());
        sessionStorage.setItem("_sessionCode", snapshot.data().idmeet);
      });
  };

  const getMeetUnits = (code) => {
    return new Promise((resolve, reject) => {
      let tempUnits = [];
      firebaseApp
        .firestore()
        .collection("meets")
        .doc(code)
        .collection("units")
        .orderBy("name", "asc")
        .get()
        .then((snapshot) => {
          if (snapshot.size !== 0) {
            snapshot.docs.forEach((doc) => {
              tempUnits.push({ doc: doc.id, ...doc.data() });
            });
            setUnits(tempUnits);
            resolve(tempUnits);
          }
        });
    });
  };

  const getMeetUnitsLive = () => {
    firebaseApp
      .firestore()
      .collection("meets")
      .doc(asamblea)
      .collection("units")
      .where("live", "==", true)
      .onSnapshot((snapshot) => {
        let coeficient = 0;
        if (snapshot.size !== 0) {
          snapshot.forEach((doc) => {
            coeficient = coeficient + parseFloat(doc.data().votePercentage);
          });

          const data = snapshot.docs.map((d) => ({
            id: d.id,
            path: d.ref,
            ...d.data(),
          }));
          setUnitsLive(data);
          setInMeetUnits(coeficient);
        }
      });
  };

  useEffect(() => {
    if (asamblea) {
      sessionStorage.setItem("codeMeet", asamblea);

      getMeet();
      getMeetUnits(asamblea);
      getMeetUnitsLive();
      getCodeInfo(asamblea).then((res) => {
        if (res) {
          let dateParts = res.data.results[0].date.split("/");
          let dateNew = dateParts[1] + "/" + dateParts[0] + "/" + dateParts[2];
          setDateMeet(dateNew + " " + res.data.results[0].hour);
        }
      });
    }

    if (tokenUser) {
      Swal.fire({
        icon: "info",
        title: "Ingresando...",
        text: "Estamos validando tu información, espera un momento.",
        didOpen: () => {
          Swal.showLoading();
        },
      });

      var bodyFormData = new FormData();
      bodyFormData.append("entranceToken", tokenUser);
      bodyFormData.append("codeMeet", asamblea);
      getLoginAdmon(bodyFormData).then((respCode) => {
        if (respCode.data.data.profile) {
          if (respCode.data.data.profile.Code) {
            getMeetUnits(respCode.data.data.profile.Code).then((resp) => {
              let unitInfo = resp.find(
                (el) => el.name === respCode.data.data.profile.unit
              );
              sessionStorage.setItem(
                "_profile",
                JSON.stringify(respCode.data.data.profile)
              );
              sessionStorage.setItem(
                "_residentialUnit",
                JSON.stringify(respCode.data.data.residentialUnit)
              );
              sessionStorage.setItem("type", "owner");

              sessionStorage.setItem(
                "_unitDoc",
                JSON.stringify({
                  doc: unitInfo.doc,
                  id: unitInfo.id,
                  name: unitInfo.name,
                  votePercentage: parseFloat(unitInfo.votePercentage),
                })
              );
              setResidentialUnit(
                JSON.parse(sessionStorage.getItem("_residentialUnit"))
              );
              firebaseApp
                .firestore()
                .collection("meets")
                .doc(asamblea)
                .collection("units")
                .doc(unitInfo.doc)
                .update({
                  live: true,
                  lastOnline: new Date().getTime(),
                })
                .then(() => {
                  Swal.close();
                  setProfile(JSON.parse(sessionStorage.getItem("_profile")));
                  setUserlink(true);
                });
            });
          } else {
            Swal.close();
            Swal.fire({
              icon: "error",
              title: "Link No Valido",
              text: "Comunicate con el administrador",
            });
          }
        } else {
          Swal.close();
          Swal.fire({
            icon: "error",
            title: "Link No Valido",
            text: "Comunicate con el administrador",
          });
        }
      });
    } else {
      getCodebyMeet(asamblea).then((respUnidad) => {
        setResidentialUnit({
          name: respUnidad.data.datos.nombreUnidad,
        });
        setUserlink(false);
      });
    }
  }, [asamblea, tokenUser]);

  const onButtonClick = () => {
    // `current` points to the mounted file input element
    inputFile.current.click();
  };

  return (
    <Fragment>
      <div
        className="cs-08-page-wrapper h-100 bg-img d-flex flex-column justify-content-between"
        style={{
          backgroundImage: `url(${background})`,
        }}
      >
        {/*====================  header ====================*/}
        <header className="cs-08-header space-mt--10 space-mb--10">
          <div className="container">
            <div className="row">
              <div className="col-4">
                {/* logo */}
                <div className="cs-08-logo">
                  <Link to={process.env.PUBLIC_URL + "/"}>
                    <img
                      src={
                        process.env.PUBLIC_URL + "/assets/img/logo/logo-08.png"
                      }
                      className="img-fluid"
                      alt=""
                    />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </header>
        {/*====================  End of header  ====================*/}
        {/*====================  content ====================*/}

        <div className="cs-08-content">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <h2 className="cs-08-content__title">
                  Bienvenido {profile.unit ? profile.unit : ""} a Asamblea
                  Virtual de {residentialUnit.name}
                </h2>
                {!meet.waitingRoom && (
                  <div>
                    <p className="cs-08-content__text">
                      La asamblea iniciara cuando el contador se acerque a la
                      hora de inicio, se permitira el ingreso a partir del
                      momento que se habilite la sala de espera
                    </p>
                    <p className="cs-08-content__text">
                      Tu asamblea inicia en :
                    </p>
                    <br />
                  </div>
                )}
                {meet.waitingRoom && (
                  <div>
                    {userLink && (
                      <>
                        <h2 className="cs-08-content__title">
                          PASO 2. Ingresar a Asamblea
                        </h2>
                        <p className="cs-08-content__text">
                          El quorum para inicio de la asamblea es de:{" "}
                          <strong>{meet.quorum} %</strong>
                        </p>
                        <p className="cs-08-content__text">
                          El quorum de la asamblea actual es:{" "}
                          <strong>{inMeetUnits} %</strong>
                        </p>
                        <div className="table-container">
                          <h5>Listado de Propietaros Conectados</h5>

                          <Table size="sm">
                            <thead>
                              <tr>
                                <th>Apartamento</th>
                                <th>Coeficiente</th>
                                <th>Estado</th>
                              </tr>
                            </thead>
                            <tbody>
                              {unitsLive.map((item, index) => (
                                <tr key={index.toString()}>
                                  <td>{item.name}</td>
                                  <td>{item.votePercentage} %</td>
                                  <td>Conectado</td>
                                </tr>
                              ))}
                            </tbody>
                          </Table>
                        </div>
                        <div style={{ marginTop: 10 }}></div>
                        {!meet.active && (
                          <div>
                            <p className="cs-08-content__text">
                              En el momento de llegar al quorum de asamblea se
                              habilitara el inicio de la transimisión.
                            </p>
                            <p
                              className="cs-08-content__text"
                              style={{ color: "red" }}
                            >
                              PERMANECE EN ESTA SALA. EL INGRESO A LA ASAMBLEA
                              AUN NO HA SIDO AUTORIZADO POR EL ADMINISTRADOR
                            </p>
                          </div>
                        )}
                        <br />
                        {meet && (
                          <Button
                            disabled={!meet.active}
                            block
                            color="info"
                            outline={!meet.active}
                            onClick={() => history.replace("/live")}
                          >
                            Unirse a Asamblea
                          </Button>
                        )}
                        <br /> <br />
                      </>
                    )}
                    {!userLink && !testConnection && (
                      <div className="row">
                        <div className="col-12">
                          <h2 className="cs-08-content__title">
                            PASO 1. Selección de Acceso
                          </h2>
                        </div>

                        <p className="col-12 cs-08-content__text">
                          Por favor selecciona tu inmueble y click en opción{" "}
                          <strong>Ingresar</strong>
                          <br />
                          <br />
                          <strong>IMPORTANTE :</strong> Ingresa por el perfil
                          adecuado para brindarte los permisos y accesos
                          adecuados a tu participación en la asamblea. Ingresar
                          en otro perfil puede ocasionar conflicto y la no
                          autorización de ingreso
                        </p>
                        <div
                          className="col-md-6 col-sm-12"
                          style={{ display: "none" }}
                        >
                          <Button
                            block
                            active={type == 1}
                            onClick={() => setType(1)}
                          >
                            Propietario o Apoderado
                          </Button>
                        </div>
                        {/*  <div className="col-md-4 col-sm-12">
                          <Button
                            block
                            active={type == 3}
                            onClick={() => setType(3)}
                          >
                            Residente
                          </Button>
                        </div> */}
                        <div
                          className="col-md-6 col-sm-12"
                          style={{ display: "none" }}
                        >
                          <Button
                            block
                            active={type == 2}
                            onClick={() => setType(2)}
                          >
                            Invitado
                          </Button>
                        </div>

                        <div className="col-12 col-md-12">
                          <br />
                          {type == 1 && (
                            <>
                              <form onSubmit={handleSubmit(onSubmit)}>
                                {/* <FormGroup>
                                <Input
                                  type="number"
                                  name="cedula"
                                  placeholder="Numero Cedula"
                                  innerRef={register({
                                    required: true,
                                  })}
                                />
                                {errors.cedula && (
                                  <Alert color="danger">
                                    El numero de cedula es obligatorio
                                  </Alert>
                                )}
                              </FormGroup> */}
                                <FormGroup>
                                  <Input
                                    type="text"
                                    name="nombreUser"
                                    placeholder="Nombre"
                                    innerRef={register({
                                      required: true,
                                    })}
                                  />
                                  {errors.nombreUser && (
                                    <Alert color="danger">
                                      El nombre es obligatorio
                                    </Alert>
                                  )}
                                </FormGroup>
                                <FormGroup>
                                  <Input
                                    type="text"
                                    name="apellidosUser"
                                    placeholder="Apellidos"
                                    innerRef={register({
                                      required: true,
                                    })}
                                  />
                                  {errors.apellidosUser && (
                                    <Alert color="danger">
                                      El apellido es obligatorio
                                    </Alert>
                                  )}
                                </FormGroup>
                                <FormGroup>
                                  <Autocomplete
                                    id="apartamentoUsuario"
                                    options={units}
                                    getOptionLabel={(option) => option.name}
                                    style={{ width: "100%" }}
                                    onChange={(e, value) => {
                                      if (value) {
                                        setApartamentoUsuario(value.doc);
                                      } else {
                                        setApartamentoUsuario("");
                                      }
                                    }}
                                    noOptionsText="Sin opciones"
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        label="Selecciona tu inmueble"
                                        variant="outlined"
                                      />
                                    )}
                                  />
                                  {/* <Input
                                  type="select"
                                  name="apartamentoUsuario"
                                  id="apartamentoUsuario"
                                  innerRef={register({
                                    required: true,
                                  })}
                                >
                                  <option key="empty" value="">
                                    Selecciona tu inmueble
                                  </option>
                                  {units.map((unit, index) => (
                                    <option
                                      key={index.toString()}
                                      value={unit.doc}
                                    >
                                      {unit.name}
                                    </option>
                                  ))}
                                </Input> */}
                                  {errors.apartamentoUsuario && (
                                    <Alert color="danger">
                                      El inmueble es obligatorio
                                    </Alert>
                                  )}
                                </FormGroup>
                                <Button block type="submit">
                                  Ingresar
                                </Button>
                              </form>
                              <p className="col-12 cs-08-content__text">
                                <br />
                                <br />
                                Si tienes problemas con el acceso o tu inmueble
                                no aparece contactenos al whatsapp{" "}
                                <a
                                  target="_blank"
                                  href="https://api.whatsapp.com/send?phone=573162712141"
                                >
                                  clic aqui
                                </a>{" "}
                                o escribanos al numero 3162712141
                              </p>
                            </>
                          )}

                          {type == 3 && (
                            <form onSubmit={handleSubmit3(onSubmitResidente)}>
                              {/* <FormGroup>
                                <Input
                                  type="number"
                                  name="cedulaResidente"
                                  placeholder="Numero Cedula"
                                  innerRef={register3({
                                    required: true,
                                  })}
                                />
                                {errors3.cedulaResidente && (
                                  <Alert color="danger">
                                    El numero de cedula es obligatorio
                                  </Alert>
                                )}
                              </FormGroup> */}
                              <FormGroup>
                                <Input
                                  type="text"
                                  name="nombreResidente"
                                  placeholder="Nombre"
                                  innerRef={register3({
                                    required: true,
                                  })}
                                />
                                {errors3.nombreResidente && (
                                  <Alert color="danger">
                                    El nombre es obligatorio
                                  </Alert>
                                )}
                              </FormGroup>
                              <FormGroup>
                                <Input
                                  type="text"
                                  name="apellidosResidente"
                                  placeholder="Apellidos"
                                  innerRef={register3({
                                    required: true,
                                  })}
                                />
                                {errors3.apellidosResidente && (
                                  <Alert color="danger">
                                    El apellido es obligatorio
                                  </Alert>
                                )}
                              </FormGroup>
                              <FormGroup>
                                <Input
                                  type="select"
                                  name="apartamentoResidente"
                                  innerRef={register3({
                                    required: true,
                                  })}
                                >
                                  <option key="empty" value="">
                                    Selecciona tu inmueble
                                  </option>
                                  {units.map((unit, index) => (
                                    <option
                                      key={index.toString()}
                                      value={unit.doc}
                                    >
                                      {unit.name}
                                    </option>
                                  ))}
                                </Input>
                                {errors3.apartamentoResidente && (
                                  <Alert color="danger">
                                    El inmueble es obligatorio
                                  </Alert>
                                )}
                              </FormGroup>
                              <Button block type="submit">
                                Buscar
                              </Button>
                            </form>
                          )}

                          {type == 2 && (
                            <Form onSubmit={handleSubmit2(onSubmitInvitado)}>
                              <FormGroup>
                                <Input
                                  type="text"
                                  name="nameInvitado"
                                  placeholder="Nombre Invitado"
                                  innerRef={register2({
                                    required: true,
                                  })}
                                />
                                {errors2.nameInvitado && (
                                  <Alert color="danger">
                                    El nombre de invitado es obligatorio
                                  </Alert>
                                )}
                              </FormGroup>
                              <FormGroup>
                                <Input
                                  type="text"
                                  name="cargoInvitado"
                                  placeholder="Cargo Invitado"
                                  innerRef={register2({
                                    required: true,
                                  })}
                                />
                                {errors2.cargoInvitado && (
                                  <Alert color="danger">
                                    El cargo del invitado es obligatorio
                                  </Alert>
                                )}
                              </FormGroup>

                              <Button block type="submit">
                                Buscar
                              </Button>
                            </Form>
                          )}
                          <br />
                        </div>
                      </div>
                    )}{" "}
                  </div>
                )}
              </div>
              {testConnection && (
                <div className="col-lg-12">
                  <div className="row">
                    <div className="col-12">
                      <h2 className="cs-08-content__title">
                        PASO 2. Prueba de Conexión{" "}
                      </h2>
                    </div>
                    <div className="col-6">
                      <p>
                        Realizar tu prueba de conexión antes de ingresar a la
                        transmisión en la cual debes brindar permisos de
                        microfono y video en el navegador
                      </p>

                      <p style={{ fontSize: 14, fontStyle: "oblique" }}>
                        Se recomienda el uso de navegador Chrome o Firefox, NO
                        usar modo incognito y revisar conexiones de microfono
                        y/o camara antes de ingresar
                      </p>
                      {showButtonPermission && !testPermission && (
                        <Button block onClick={() => onTestClick()}>
                          Realizar Prueba
                        </Button>
                      )}
                      {showTestPermission && (
                        <h3>
                          Acepta el permiso de camara y/o microfono en la parte
                          superior del navegador
                        </h3>
                      )}
                      {testPermission && (
                        <div>
                          <video
                            autoPlay
                            style={{
                              width: "100%",
                              minHeight: "300px",
                              backgroundColor: "black",
                            }}
                          ></video>
                          <Alert color="success">Conexión Satisfactoria</Alert>
                          <Button
                            block
                            onClick={() => setTestPermission(false)}
                          >
                            Cerrar Prueba
                          </Button>
                        </div>
                      )}
                      <div style={{ marginTop: 20 }}></div>
                    </div>
                    <div className="col-6">
                      <iframe
                        width="100%"
                        height="250px"
                        src="https://www.youtube.com/embed/huH_ASK4wfE"
                        style={{ border: 0 }}
                      ></iframe>
                    </div>
                    <div className="col-12">
                      <Button
                        block
                        color="info"
                        onClick={() => passToWaitingRoom()}
                      >
                        Siguiente
                      </Button>
                      <br />
                      <br />
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="container">
            <div className="row">
              <div className="col-12">
                {/*  {meet.active && userLink && (
                    <Link to="/live">
                      <Button color="info">Unirse a Asamblea</Button>
                    </Link>
                  )} */}

                <div className="cs-08-countdown">
                  {dateMeet != "" && (
                    <Countdown date={new Date(dateMeet)} renderer={Renderer} />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        {/*====================  End of content  ====================*/}
        {/*====================  footer ====================*/}
        <footer className="cs-08-footer space-pt--25 space-pb--25">
          <div className="container">
            <div className="row align-items-center">
              <img
                className="logo_foot"
                src="https://admon.com.co/wp-content/uploads/2020/10/1-e1602621974453.png"
              />
            </div>
          </div>
        </footer>
        {/*====================  End of footer  ====================*/}
      </div>
    </Fragment>
  );
};

export default ComingSoon08;
